import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Language from "../../view/Components/Language";
import Logo from '../../assets/img/logo/logo.png';
import LogoDark from '../../assets/img/logo/logo_dark.png';
import './css/style.css';
import {  useNavigate } from "react-router-dom";
const CompLink = ({ children, toId }) => {
  return (

    <Link className=" active nav-link" style={{ cursor: 'pointer' }} to={toId} spy={true} smooth={true} offset={-70} duration={500}>{children}</Link>
  )
}

const navBar = [/* {
  children: "Website.nav.home",
  toId: "fh5co-home"
},
{
  children: "Website.nav.our_solution",
  toId: "features"
}, */
/* {
  children: "Website.nav.pictures",
  toId: "howitworks"
},
{
  children: "Website.nav.contact",
  toId: "gtco-footer"
}, */
]

function NavBar(props) {
  const [scrolled, setScrolled] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = ['navbar', 'navbar-expand-lg', 'fixed-top', 'custom-nav', 'sticky'];
  if (scrolled) {
    navbarClasses.push('sticky-header');
  }


  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(false);
  const handleModal = (type) => {
    setShowModal(true)
    setType(type)
  }

  return (
    <div _ngcontent-vyj-c19="" ng-version="9.1.9">
      <nav className={navbarClasses.join(' ')}>
        <div className="container">
          {/* <img
            src={Logo}
            className="logo"
            alt="hola"
            style={{ filter: 'drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.7))' }}
          /> */}

          <a
            routerlink="/index"
            className="navbar-brand brand-logo mr-4"
            style={{
              cursor: 'pointer',
            }}

          ><img
              src={Logo}
              alt=""
              className="img-fluid logo-light"
              style={{ filter: 'drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.7))' }} />
            <img
              src={LogoDark}
              alt=""
              className="img-fluid logo-dark"
            /></a>
          <div
            id="navbarCollapse"
            className={"navbar-collapse collapse justify-content-center  " + (show ? "show" : "")}
          >


            <ul id="mySidenav" className="navbar-nav navbar-center" style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
            }}>
              {navBar.map((data, index) => {

                return (
                  <li key={index} routerlinkactive="active" className="nav-item">
                    <CompLink key={index} toId={data.toId}>{props.t(data.children)}</CompLink>
                  </li>

                )
              })}
              {/* <li className="nav-item">
                <a
                  className="nav-link"

                  style={{
                    color: 'gold',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  data-aos="zoom-out"
                  data-aos-delay="150"
                  onClick={() => Navigate.push('/register')}
                >
                  {props.t("Website.nav.register")}
                </a>
              </li>
              <li className="nav-item">
                <a


                  href="/#/login"
                  className="nav-link"
                  style={{
                    color: 'gold',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  data-aos="zoom-out"
                  data-aos-delay="50"
                >
                  {props.t("Website.nav.login")}
                </a>
              </li> */}


            </ul>
            <div className="menu-close-btn" onClick={() => setShow(!show)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </div>
          </div>



          <Language color="314584" />
          <div className="header_btn">
            <button
              type="button"
              id="sidebarCollapse"
              className="navbar-toggler ml-2 p-0"
              onClick={() => setShow(!show)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
              </svg>
            </button>
          </div>

        </div>

      </nav>
    </div>

  );
}


export default NavBar;

