import React, { useEffect, useState, useRef } from 'react';



function Testimonials(props) {
  const [count, setCount] = useState(0);

  const [count2, setCount2] = useState(0);

  const [count3, setCount3] = useState(0);

  const [count4, setCount4] = useState(0);
  const counterRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const targetCount = 360;
        const duration = 3000;
        const incrementTime = duration / targetCount;

        const timer = setInterval(() => {
          setCount((prevCount) => {
            if (prevCount < targetCount) {
              return prevCount + 5;
            } else {
              clearInterval(timer);
              return targetCount;
            }
          });
          setCount2((prevCount) => {
            if (prevCount < targetCount) {
              return prevCount + 2;
            } else {
              clearInterval(timer);
              return targetCount;
            }
          });
          setCount3((prevCount) => {
            if (prevCount < targetCount) {
              return prevCount + 3;
            } else {
              clearInterval(timer);
              return targetCount;
            }
          });
          setCount4((prevCount) => {
            if (prevCount < targetCount) {
              return prevCount + 4;
            } else {
              clearInterval(timer);
              return targetCount;
            }
          });
        }, incrementTime);

        return () => clearInterval(timer);
      }
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  return (
    <section className="parallax parallax-box section mt-11">
      <div id="counter">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="counter-box text-center">
                <div className="counter-ico">

                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-rocket-takeoff-fill" viewBox="0 0 16 16">
                    <path d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z" />
                    <path d="M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925" />
                  </svg>
                </div>
                <h3
                  id="number1"
                  data-count="12"
                  className="number counter-value"
                >
                  {count}
                </h3>
                <span></span>
                <p>{props.t("Website.numbers.active")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="counter-box text-center">
                <div className="counter-ico">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-clipboard-data" viewBox="0 0 16 16">
                    <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                  </svg>
                </div>
                <h3
                  id="number2"
                  data-count="55"
                  className="number counter-value"
                >
                  {count2}
                </h3>
                <span></span>
                <p>{props.t("Website.numbers.business")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="counter-box text-center">
                <div className="counter-ico">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-card-checklist" viewBox="0 0 16 16">
  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
  <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
</svg>
                </div>
                <h3
                  id="number3"
                  data-count="360"
                  className="number counter-value"
                >
                  {count3}
                </h3>
                <span></span>
                <p>{props.t("Website.numbers.project")}</p>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="counter-box text-center">
                <div className="counter-ico">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-person-hearts" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4m13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"/>
</svg>
                </div>
                <h3
                  id="number4"
                  data-count="10"
                  className="number counter-value"
                  ref={counterRef}
                >
                  {count4}
                </h3>
                <span></span>
                <p>{props.t("Website.numbers.client")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default Testimonials;