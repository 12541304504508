import React, { Component } from "react";
import About01 from "./css/about01.png";
import About02 from "./css/about02.png";
import About03 from "./css/about03.webp";

class Design extends Component {

  render() {

    return (
      <section id="aboutus" className="section" style={{background:'#FFF'}}>
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2><span>{this.props.t("Website.contact.title")}</span></h2>

              <p className="section_subtitle mx-auto">
                {this.props.t("Website.contact.subtitle")}
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
                className="about-image-block wow fadeInUp"

              >
                <img data-aos="fade-down-right"
                  src={About01}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
                className="about-text-block wow fadeInUp"

              >
                <div className="about-content" data-aos="fade-down-left">
                  <h4 style={{ whiteSpace: 'pre-wrap' }}>{this.props.t("Website.contact.detailsTittle")}</h4>
                  <div className="text">
                    {this.props.t("Website.contact.details")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12 order-md-2">
              <div
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
                className="about-image-block wow fadeInUp"

              >
                <img
                  data-aos="fade-up-left"
                  src={About02}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 order-md-1" data-aos="fade-up-right">
              <div
                className="about-text-block wow "
              >
                <div className="about-content" >
                  <h4 style={{ whiteSpace: 'pre-wrap' }}> {this.props.t("Website.contact.subtitle2")}</h4>
                  <div className="text">
                    {this.props.t("Website.contact.details2")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
                className="about-image-block wow fadeInUp"

              >
                <img data-aos="fade-down-right"
                  src={About03}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
                className="about-text-block wow fadeInUp"

              >
                <div className="about-content" data-aos="fade-down-left">
                  <h4 style={{ whiteSpace: 'pre-wrap' }}>{this.props.t("Website.contact.subtitle3")}</h4>
                  <div className="text">
                    {this.props.t("Website.contact.details3")}
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
    );
  }
}

export default Design;