/* eslint-disable no-unused-vars */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import AOS from "aos";
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS


import NavBar from "./components/01-NavBar";
import BannerSection from "./components/02-BannerSection";
import Design from "./components/03-Design";
import Services from "./components/04-Services";
import Numbers from "./components/05-Numbers";
import Testimonials from "./components/06-Testimonials";
import Pricing from "./components/10-Pricing";

import "../css/style.css";




const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Website extends Component {

  componentDidMount() {
    AOS.init(); // Inicializa AOS en el componente
  }

  constructor(props) {
    super(props);
    this.change = this.change.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.getDisabled = this.getDisabled.bind(this);

    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false,
      navbar: false,
    };
  }

  change = (e) => {
    const { name, value } = e.target;
    var state = this.state;
    state[name] = value;
    this.setState(state);
  };

  getDisabled = () => {
    var state = this.state;

    var res = false;

    if (_.isEmpty(state.name) || _.isEmpty(state.message)) {
      res = true;
    }

    if (!isEmail(state.email)) {
      res = true;
    }

    return res;
  };

  sendMessage = async (e) => {
    e.preventDefault();
    var state = this.state;
    await fetch(
      auth.prepareURL(process.env.REACT_APP_API_URL_SEND_MESSAGE),
      auth.getRequestInit("post", state)
    );
    state.sent = true;
    this.setState(state);
  };

  updateNavbar = (e) => {
    e.preventDefault();
    var state = this.state;
    state.navbar = !state.navbar;
    this.setState(state);
  };

  render() {
    const { t } = this.props;
    const { name, email, message, sent, navbar } = this.state;

    return (
      <>
        <NavBar {...this.props} />
        <BannerSection t={t} />
        <Design t={t} />
        <Services t={t} />

        <Numbers t={t} />
        <Testimonials t={t} />

        {/*<Tags t={t}/>*/}
        {/* <Pricing t={t} /> */}
        {/*<Gallery t={t}/>
      
      <Footer t={t}/> */}


      </>
    );
  }
}

Website.propTypes = propTypes;
Website.defaultProps = defaultProps;

export default withTranslation()(Website);