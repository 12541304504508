
import React, { Component } from 'react';
import CheckIcon from '@mui/icons-material/Check';


function Services(props) {


  return (
    <section id="features" className="section bg-light" style={{ background: '#f8f9fa' }}>
      <ul className="circles fetures-circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container">

        <div className="section_title mb-4 text-center">

          <h2><span>{props.t("Website.solution.tittle")}</span></h2>

          <p className="section_subtitle mx-auto">
             {props.t("Website.services.tittle")}
          </p>
        </div>
        <div className="container gtco-featured">
          <div className="row">

            <div className="col-lg-12 our-services">
              <svg id="bg-services" width="100%" viewBox="0 0 1200 1000">
                <defs>
                  <linearGradient
                    id="PSgrad_02"
                    x1="64.279%"
                    x2="0%"
                    y1="76.604%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      stopColor="rgb(1,230,248)"
                      stopOpacity="1"
                    />
                    <stop
                      offset="100%"
                      stopColor="rgb(29,62,222)"
                      stopOpacity="1"
                    />
                  </linearGradient>
                </defs>
                <path
                  fillRule="evenodd"
                  opacity="0.102"
                  fill="url(#PSgrad_02)"
                  d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"
                />
              </svg>
              <div className="row">
                <div className="col">
                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval">
                      <svg
                        stroke="currentColor"
                        className="svg"
                        fill="currentColor"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#1565C0"
                          d="M25,22h13l6,6V11c0-2.2-1.8-4-4-4H25c-2.2,0-4,1.8-4,4v7C21,20.2,22.8,22,25,22z"
                        ></path>
                        <path
                          fill="#2196F3"
                          d="M23,19H10l-6,6V8c0-2.2,1.8-4,4-4h15c2.2,0,4,1.8,4,4v7C27,17.2,25.2,19,23,19z"
                        ></path>
                        <g fill="#FFA726">
                          <circle cx="12" cy="31" r="5"></circle>
                          <circle cx="36" cy="31" r="5"></circle>
                        </g>
                        <g fill="#607D8B">
                          <path d="M20,42c0,0-2.2-4-8-4s-8,4-8,4v2h16V42z"></path>
                          <path d="M44,42c0,0-2.2-4-8-4s-8,4-8,4v2h16V42z"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        {props.t("Website.solution.title_1")}
                      </h3>
                      <div className="card-text" style={{
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        gap: 10,
                        flexDirection: 'column',
                      }}>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_1.1")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_1.2")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_1.3")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card text-center" data-aos="flip-left" data-aos-delay="150">
                    <div className="oval">
                      <svg
                        className="svg"
                        stroke="currentColor"
                        fill="currentColor"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#CFD8DC"
                          d="M5,38V14h38v24c0,2.2-1.8,4-4,4H9C6.8,42,5,40.2,5,38z"
                        ></path>
                        <path
                          fill="#F44336"
                          d="M43,10v6H5v-6c0-2.2,1.8-4,4-4h30C41.2,6,43,7.8,43,10z"
                        ></path>
                        <g fill="#B71C1C">
                          <circle cx="33" cy="10" r="3"></circle>
                          <circle cx="15" cy="10" r="3"></circle>
                        </g>
                        <g fill="#B0BEC5">
                          <path d="M33,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C35,3.9,34.1,3,33,3z"></path>
                          <path d="M15,3c-1.1,0-2,0.9-2,2v5c0,1.1,0.9,2,2,2s2-0.9,2-2V5C17,3.9,16.1,3,15,3z"></path>
                        </g>
                        <g fill="#90A4AE">
                          <rect x="13" y="20" width="4" height="4"></rect>
                          <rect x="19" y="20" width="4" height="4"></rect>
                          <rect x="25" y="20" width="4" height="4"></rect>
                          <rect x="31" y="20" width="4" height="4"></rect>
                          <rect x="13" y="26" width="4" height="4"></rect>
                          <rect x="19" y="26" width="4" height="4"></rect>
                          <rect x="25" y="26" width="4" height="4"></rect>
                          <rect x="31" y="26" width="4" height="4"></rect>
                          <rect x="13" y="32" width="4" height="4"></rect>
                          <rect x="19" y="32" width="4" height="4"></rect>
                          <rect x="25" y="32" width="4" height="4"></rect>
                          <rect x="31" y="32" width="4" height="4"></rect>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">{props.t("Website.solution.title_2")}
                      </h3>
                      <div className="card-text" style={{
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        gap: 10,
                        flexDirection: 'column',
                      }}>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_2.1")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_2.2")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_2.3")}</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card text-center" data-aos="flip-left" data-aos-delay="200">
                    <div className="oval">
                      <svg
                        className="svg"
                        stroke="currentColor"
                        fill="currentColor"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#1976D2"
                          d="M38.1,31.2L19.4,24l18.7-7.2c1.5-0.6,2.3-2.3,1.7-3.9c-0.6-1.5-2.3-2.3-3.9-1.7l-26,10C8.8,21.6,8,22.8,8,24 s0.8,2.4,1.9,2.8l26,10c0.4,0.1,0.7,0.2,1.1,0.2c1.2,0,2.3-0.7,2.8-1.9C40.4,33.5,39.6,31.8,38.1,31.2z"
                        ></path>
                        <g fill="#1E88E5">
                          <circle cx="11" cy="24" r="7"></circle>
                          <circle cx="37" cy="14" r="7"></circle>
                          <circle cx="37" cy="34" r="7"></circle>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        {props.t("Website.solution.title_3")}
                      </h3>
                      <div className="card-text" style={{
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        gap: 10,
                        flexDirection: 'column',
                      }}>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_3.1")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_3.2")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_3.3")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card text-center" data-aos="flip-left" data-aos-delay="200">
                    <div className="oval">
                      <svg
                        className="svg"
                        stroke="currentColor"
                        fill="currentColor"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#455A64"
                          d="M36,4H26c0,1.1-0.9,2-2,2s-2-0.9-2-2H12C9.8,4,8,5.8,8,8v32c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V8 C40,5.8,38.2,4,36,4z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M36,41H12c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1v32C37,40.6,36.6,41,36,41z"
                        ></path>
                        <g fill="#90A4AE">
                          <path d="M26,4c0,1.1-0.9,2-2,2s-2-0.9-2-2h-7v4c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V4H26z"></path>
                          <path d="M24,0c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S26.2,0,24,0z M24,6c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2 S25.1,6,24,6z"></path>
                        </g>
                        <g fill="#CFD8DC">
                          <rect x="21" y="20" width="12" height="2"></rect>
                          <rect x="15" y="19" width="4" height="4"></rect>
                        </g>
                        <g fill="#03A9F4">
                          <rect x="21" y="29" width="12" height="2"></rect>
                          <rect x="15" y="28" width="4" height="4"></rect>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">{props.t("Website.solution.title_4")}
                      </h3>
                      <div className="card-text" style={{
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        gap: 10,
                        flexDirection: 'column',
                      }}>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_4.1")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_4.2")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_4.3")}</div>
                      </div>
                    </div>
                  </div>



                </div>
                <div className="col" style={{ marginTop: 60 }}>
                  <div className="card text-center" data-aos="flip-left" data-aos-delay="0">
                    <div className="oval">
                      <svg
                        className="svg"
                        stroke="currentColor"
                        fill="currentColor"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon
                          fill="#90CAF9"
                          points="40,45 8,45 8,3 30,3 40,13"
                        ></polygon>
                        <polygon
                          fill="#E1F5FE"
                          points="38.5,14 29,14 29,4.5"
                        ></polygon>
                        <g fill="#1976D2">
                          <rect x="16" y="21" width="17" height="2"></rect>
                          <rect x="16" y="25" width="13" height="2"></rect>
                          <rect x="16" y="29" width="17" height="2"></rect>
                          <rect x="16" y="33" width="13" height="2"></rect>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        {props.t("Website.solution.title_5")}
                      </h3>
                      <div className="card-text" style={{
                        textAlign: 'left',
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: 'flex',
                        gap: 10,
                        flexDirection: 'column',
                      }}>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_5.1")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_5.2")}</div>
                        <div><CheckIcon style={{
                          position: 'relative',
                        }} />{props.t("Website.solution.list_5.3")}</div>
                      </div>
                    </div>
                  </div>


                 {/*  <div className="card text-center" data-aos="flip-left" data-aos-delay="200">
                    <div className="oval">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        className="svg"
                        version="1"
                        viewBox="0 0 48 48"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="#3F51B5">
                          <polygon points="17.8,18.1 10.4,25.4 6.2,21.3 4,23.5 10.4,29.9 20,20.3"></polygon>
                          <polygon points="17.8,5.1 10.4,12.4 6.2,8.3 4,10.5 10.4,16.9 20,7.3"></polygon>
                          <polygon points="17.8,31.1 10.4,38.4 6.2,34.3 4,36.5 10.4,42.9 20,33.3"></polygon>
                        </g>
                        <g fill="#90CAF9">
                          <rect x="24" y="22" width="20" height="4"></rect>
                          <rect x="24" y="9" width="20" height="4"></rect>
                          <rect x="24" y="35" width="20" height="4"></rect>
                        </g>
                      </svg>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">{props.t("Website.solution.title_4")}
                      </h3>
                      <p className="card-text">
                        {props.t("Website.solution.desc_4")}
                      </p>
                    </div>
                  </div>
 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}


export default Services;