import React from "react";
import "./style.css";
import WelcomeImg from './css/hero-banner.png';
import DownArrow from './css/down-arrow.png';
import { Link } from "react-scroll";

import { Typewriter } from 'react-simple-typewriter';

import i18n from '../../services/i18n';

function BannerSection({ t }) {

  return <div role="banner" className="toolbar">
    <section id="home" className="home-section">
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="home-bg">
        <div className="welcome-text-area">
          <div className="area-bg"></div>
          <div className="welcome-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="welcome-text text-center"   >
                    <h1
                      data-aos="fade-in-up"
                      style={{ color: '#fff', fontWeight: 'bold', fontSize: '2.5em' }}

                    >
                      {t("banner.title")}

                    </h1>
                    <h2 style={{ color: '#EEE', fontWeight: '600', fontSize: '1.7em', height:40 }}>
                      <Typewriter
                      key={i18n.language}
                        words={[t("banner.subtitle")]} // Asegura que siempre haya al menos un carácter

                        loop={1}

                        typeSpeed={50}
                        deleteSpeed={20}
                        delaySpeed={100}
                      />
                    </h2>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 35 }}>
                    <a
                      href="https://calendly.com/ethan-s-harris"
                      className="btn my-4 my-sm-0 text-uppercase"
                      target="_blank"
                      style={{
                        borderColor: "#fff",
                        fontSize: "1.7rem",
                        color: "#fff",
                        padding: "5px 25px",
                        borderRadius: 20,
                        borderWidth: 2,
                      }}
                      data-aos="zoom-out"
                      data-aos-delay="150"
                    >
                      {t("banner.button")}
                    </a>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="1000"
                    className="welcome-img d-flex justify-content-center align-items-center wow fadeInUp"

                  >
                    <img
                      src={WelcomeImg}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link className="scroll-next" style={{ cursor: 'pointer' }} to="aboutus" spy={true} smooth={true} offset={-70} duration={500}>
        <img
          src={DownArrow}
          alt=""
          className="img-fluid" />
      </Link>

    </section >
  </div >

}

export default BannerSection;
